import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { closeFlyout } from "src/static/js/commons.js";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { formatDateFullYear } from "src/features/common/helper";
import { getTaskState } from "./WorkflowTaskStepTooltip";
import { stepClass } from "./WorkflowTaskStep";
import { inProgressStatus, onHoldStatus, skippedStatus } from "src/features/common/constants";
export function WorkFlowTaskFlyout(_a) {
    var setIsDrilldownOpen = _a.setIsDrilldownOpen, workflowTask = _a.workflowTask;
    var closeDrillDownView = function () {
        setIsDrilldownOpen();
        closeFlyout();
    };
    var closeTooltip = useIntl().formatMessage({
        id: "app.ces.responseRate.drilldownTooltipClose"
    });
    return (_jsxs("div", { className: "flyout-panel half-flyout show", role: "dialog", id: "drill-down-view", tabIndex: -1, "aria-label": "Workflow Task flyout ", children: [_jsx("div", { className: "overlay" }), _jsxs("div", { className: "container-box fs-rpt-flyout", children: [_jsx("div", { className: "paper-card", children: _jsxs("div", { className: "overlay-content", children: [_jsx("div", { className: "helper-text", children: _jsx("div", { className: "description", children: workflowTask.title }) }), _jsx(WmButton, { buttonType: "navigational", icon: "f156", tooltipPosition: "bottom", tooltip: closeTooltip, id: "fs-rpt-close-btn", class: "hydrated", iconSize: "18px", onClick: closeDrillDownView })] }) }), _jsx("section", { id: "fs-rpt-table", className: "workflowtasks-steps-table", children: _jsxs(Table, { children: [_jsx(TableHeader, {}), _jsx(TableBody, { workflowTask: workflowTask })] }) }), _jsx("div", { className: "fs-rpt-table-border " }), _jsx("div", { className: "close-button-container", children: _jsx(WmButton, { class: "close-work-flow-task", buttonType: "primary", onClick: closeDrillDownView, children: translate("app.ces.responseRate.drilldownTooltipClose") }) })] })] }));
}
function submittedDate(submittedDate) {
    return _jsx("div", { className: "date", children: formatDateFullYear(submittedDate) });
}
function dueDate(dueDate) {
    return _jsx("div", { className: "date", children: formatDateFullYear(dueDate) });
}
var GetDate = function (_a) {
    var step = _a.step;
    switch (true) {
        case !!step.submittedDate:
            return submittedDate(step.submittedDate);
        case !!step.dueDate:
            return dueDate(step.dueDate);
        default:
            return "-";
    }
};
var WorkflowTaskAssigneeName = function (_a) {
    var step = _a.step, assignee = _a.assignee, index = _a.index;
    return (_jsxs("div", { children: [_jsxs("span", { children: [" ", "".concat(assignee.firstName)] }), _jsxs("span", { children: [" ", assignee.middleName ? assignee.middleName : ""] }), _jsxs("span", { children: [" ", assignee.lastName] }), index < step.assignees.length - 1 ? "," : ""] }));
};
var WorkflowTaskRow = function (_a) {
    var step = _a.step;
    return (_jsxs("tr", { className: step.state === inProgressStatus ? "in-progress datarow" : "datarow", children: [_jsx("td", { className: "datacell", children: _jsx("div", { className: "tooltip-state ".concat(stepClass(step === null || step === void 0 ? void 0 : step.state)), children: getTaskState(step === null || step === void 0 ? void 0 : step.state) }) }), _jsx("td", { className: "datacell", children: step.stepIndex + 1 }), _jsx("td", { className: "datacell", children: step.name }), _jsx("td", { className: "datacell", children: step.assignees.map(function (assignee, index) {
                    return (_jsx(WorkflowTaskAssigneeName, { step: step, assignee: assignee, index: index }, "row-".concat(index, "-").concat(step.id)));
                }) }), _jsx("td", { className: "datacell", children: step.state === onHoldStatus || step.state === skippedStatus ? ("-") : (_jsx(GetDate, { step: step })) })] }));
};
var TableBody = function (_a) {
    var workflowTask = _a.workflowTask;
    return (_jsx("tbody", { className: "databody", children: workflowTask.steps.map(function (step, index) {
            return _jsx(WorkflowTaskRow, { step: step }, "body-".concat(index));
        }) }));
};
var Table = function (props) {
    return (_jsx("table", { id: "flyout-table", className: "data-table -shadow", children: props.children }));
};
var TableHeader = function () {
    var columns = [
        translate("app.home.WorkflowTaskFlyout.status"),
        translate("app.home.WorkflowTaskFlyout.id"),
        translate("app.home.WorkflowTaskFlyout.StepName"),
        translate("app.home.WorkflowTaskFlyout.AssigneeName"),
        translate("app.home.WorkflowTaskFlyout.dueOrCompletedDate")
    ];
    return (_jsx("thead", { className: "dataheader", children: _jsx("tr", { className: "dataheaderrow", children: columns.map(function (column, index) {
                return (_jsx("th", { className: "dataheadercell", "data-th": column, children: _jsx("span", { children: column }) }, "column-".concat(index)));
            }) }) }));
};
