import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon, SentBackIcon, SkippedIcon, OnHoldIcon, TickIcon } from "src/features/common/Icons";
import { isStepsLessThanEqualToEdgeCase, isStepsMoreThanEdgeCase } from "./WorkflowTaskProgress";
export function stepClass(state) {
    switch (state) {
        case "IN_PROGRESS":
            return "step-in-progress";
        case "NOT_STARTED":
            return "step-not-started";
        case "SUBMITTED":
            return "step-submitted";
        case "SENT_BACK":
            return "step-sent-back";
        case "SKIPPED":
            return "step-skipped";
        case "ON_HOLD":
            return "step-on-hold";
        default:
            return "";
    }
}
export function WorkflowTaskStep(_a) {
    var step = _a.step, allExpanded = _a.allExpanded, openTooltip = _a.openTooltip, clickedStep = _a.clickedStep, expandStepOnClick = _a.expandStepOnClick, stepsLength = _a.stepsLength;
    var showFullNameOnClick = clickedStep === step.stepIndex;
    function stepClassTooltip() {
        return showFullNameOnClick ? "full-width" : "";
    }
    function showInProgressStepName() {
        return showFullNameOnClick || clickedStep === -1;
    }
    function expandedWithName() {
        return showInProgressStepName() && step.state === "IN_PROGRESS"
            ? "show-expanded"
            : "";
    }
    function edgesClass() {
        if (step.stepIndex === 0) {
            return "first-step";
        }
        else if (stepsLength - 1 === step.stepIndex) {
            return "last-step";
        }
        else
            return "";
    }
    function edgeCaseSteps() {
        return isStepsMoreThanEdgeCase(stepsLength) ? "edge-case" : "";
    }
    function handleClickStep(event, stepIndex) {
        var stepElement = document.querySelector('.step[tabIndex="0"]');
        if (stepElement) {
            stepElement.tabIndex = -1;
        }
        expandStepOnClick(stepIndex);
        openTooltip(event, stepIndex);
    }
    return (_jsx("div", { className: allExpanded
            ? "step tabbing ".concat(stepClass(step.state), " hover  ").concat(edgesClass())
            : "step tabbing ".concat(edgesClass(), " ").concat(stepClass(step.state), " ").concat(stepClassTooltip(), " ").concat(expandedWithName(), "  hover ").concat(edgeCaseSteps()), onClick: function (event) {
            if (isStepsLessThanEqualToEdgeCase(stepsLength)) {
                handleClickStep(event, step.stepIndex);
            }
            else {
                event.preventDefault();
            }
        }, children: allExpanded ? (_jsxs("div", { className: "flex-row in-progress-content", children: [step.state !== "NOT_STARTED" && (_jsxs("div", { className: "clock-icon", children: [step.state === "IN_PROGRESS" && _jsx(ClockIcon, {}), step.state === "SUBMITTED" && _jsx(TickIcon, {}), step.state === "SENT_BACK" && (_jsx("div", { className: "sent-back", children: _jsx(SentBackIcon, {}) })), step.state === "SKIPPED" && (_jsx("div", { className: "skipped", children: _jsx(SkippedIcon, {}) })), step.state === "ON_HOLD" && (_jsx("div", { className: "on-hold", children: _jsx(OnHoldIcon, {}) }))] })), _jsx("div", { className: "step-name step-name-expanded", children: step.name })] })) : (_jsxs("div", { className: "step-content", children: [step.state === "IN_PROGRESS" && (_jsxs("div", { className: "flex-row in-progress-content", children: [_jsx("div", { className: "clock-icon", children: _jsx(ClockIcon, {}) }), showInProgressStepName() && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "SUBMITTED" && (_jsxs("div", { className: "flex-row in-progress-content", children: [(isStepsLessThanEqualToEdgeCase(stepsLength) ||
                            showFullNameOnClick) && (_jsx("div", { className: "clock-icon", children: _jsx(TickIcon, {}) })), showFullNameOnClick && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "SENT_BACK" && (_jsxs("div", { className: "flex-row in-progress-content", children: [(isStepsLessThanEqualToEdgeCase(stepsLength) ||
                            showFullNameOnClick) && (_jsx("div", { className: "sent-back-icon", children: _jsx(SentBackIcon, {}) })), showFullNameOnClick && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "SKIPPED" && (_jsxs("div", { className: "flex-row in-progress-content", children: [(isStepsLessThanEqualToEdgeCase(stepsLength) ||
                            showFullNameOnClick) && (_jsx("div", { className: "sent-back-icon", children: _jsx(SkippedIcon, {}) })), showFullNameOnClick && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "ON_HOLD" && (_jsxs("div", { className: "flex-row in-progress-content", children: [(isStepsLessThanEqualToEdgeCase(stepsLength) ||
                            showFullNameOnClick) && (_jsx("div", { className: "sent-back-icon", children: _jsx(OnHoldIcon, {}) })), showFullNameOnClick && (_jsx("div", { className: "step-name", children: step.name }))] })), step.state === "NOT_STARTED" &&
                    (showFullNameOnClick ? (_jsx("div", { className: "step-name", children: step.name })) : (isStepsLessThanEqualToEdgeCase(stepsLength) && step.stepIndex + 1))] })) }));
}
