import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from "react-intl";
export default function EdgeCaseArrowButton(_a) {
    var direction = _a.direction, workflowTask = _a.workflowTask, closeTooltip = _a.closeTooltip, clickedStep = _a.clickedStep, openTooltip = _a.openTooltip, setClickedStep = _a.setClickedStep;
    function expandRight(totalSteps, stepContainer, currentStepIndex, event) {
        if (totalSteps > 0) {
            closeTooltip();
            var nextIndex_1 = (currentStepIndex + 1) % totalSteps;
            var nextElement_1 = stepContainer.children[nextIndex_1];
            if (event.key === "Enter") {
                nextElement_1.tabIndex = 0;
                nextElement_1.focus();
            }
            setClickedStep(nextIndex_1);
            setTimeout(function () {
                openTooltip(event, nextIndex_1, nextElement_1, undefined, "right");
            }, 100);
        }
    }
    function expandLeft(totalSteps, stepContainer, currentStepIndex, event) {
        if (totalSteps > 0) {
            closeTooltip();
            var prevIndex_1 = currentStepIndex === -1
                ? totalSteps - 1
                : (currentStepIndex - 1 + totalSteps) % totalSteps;
            var prevElement_1 = stepContainer.children[prevIndex_1];
            if (event.key === "Enter") {
                prevElement_1.tabIndex = 0;
                prevElement_1.focus();
            }
            setClickedStep(prevIndex_1);
            setTimeout(function () {
                openTooltip(event, prevIndex_1, prevElement_1, undefined, "left");
            }, 100);
        }
    }
    function handleArrowButtonClick(event, direction) {
        if (event.key && event.key !== "Enter") {
            return;
        }
        var steps = workflowTask.steps;
        var totalSteps = steps.length;
        var currentStepIndex = clickedStep;
        var parent = event.target.closest(".task-progress-container");
        var stepContainer = parent.querySelector(".steps-container");
        switch (direction) {
            case "right":
                event.preventDefault();
                expandRight(totalSteps, stepContainer, currentStepIndex, event);
                break;
            case "left":
                event.preventDefault();
                expandLeft(totalSteps, stepContainer, currentStepIndex, event);
                break;
            default:
                break;
        }
    }
    var rightArrow = useIntl().formatMessage({
        id: "app.home.reviewsInProgress.rightArrow"
    });
    var leftArrow = useIntl().formatMessage({
        id: "app.home.reviewsInProgress.leftArrow"
    });
    return (_jsx(_Fragment, { children: _jsx("div", { className: direction === "left" ? "left-button" : "right-button", children: _jsx("button", { className: "edge-case-button", onClick: function (event) {
                    return handleArrowButtonClick(event, direction === "left" ? "left" : "right");
                }, onKeyDown: function (event) {
                    return handleArrowButtonClick(event, direction === "left" ? "left" : "right");
                }, "aria-label": direction === "left" ? leftArrow : rightArrow, children: _jsx("span", { className: direction === "left"
                        ? "mdi mdi-arrow-left"
                        : "mdi mdi-arrow-right" }) }) }) }));
}
