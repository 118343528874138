import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon, JointAppointmentsIcon, SentBackIcon, SkippedToIcon, TickIcon, TooltipArrow } from "src/features/common/Icons";
import { isStepsMoreThanEdgeCase } from "./WorkflowTaskProgress";
import { stepClass } from "./WorkflowTaskStep";
import { formatDateFullYear } from "src/features/common/helper";
import { useIntl } from "react-intl";
import translate from "src/i18n/translate";
import { WmButton } from "@watermarkinsights/ripple-react";
import useSmallScreenView from "src/features/common/useSmallScreenView";
import { useContext } from "react";
import { AppContext } from "src/contexts/AppContext";
import { inProgressStatus, submittedStatus } from "src/features/common/constants";
export function getTaskState(state) {
    switch (state) {
        case "SUBMITTED":
            return useIntl().formatMessage({
                id: "app.home.reviewsInProgress.completedState"
            });
        case "IN_PROGRESS":
            return useIntl().formatMessage({
                id: "app.home.reviewsInProgress.inProgressState"
            });
        case "NOT_STARTED":
            return useIntl().formatMessage({
                id: "app.home.reviewsInProgress.notStartedState"
            });
        case "SENT_BACK":
            return useIntl().formatMessage({
                id: "app.home.reviewsInProgress.sentBack"
            });
        case "SKIPPED":
            return useIntl().formatMessage({
                id: "app.home.reviewsInProgress.skipped"
            });
        case "ON_HOLD":
            return useIntl().formatMessage({
                id: "app.home.reviewsInProgress.onHold"
            });
    }
}
export function WorkflowTaskStepTooltip(_a) {
    var tooltipData = _a.tooltipData, tooltipRef = _a.tooltipRef, closeTooltip = _a.closeTooltip, closeEdgeCaseTriggered = _a.closeEdgeCaseTriggered, stepsLength = _a.stepsLength;
    var isSmallScreen = useSmallScreenView(1124);
    var stepIndex = useIntl().formatMessage({ id: "app.home.reviewsInProgress.stepIndex" }, {
        stepIndex: tooltipData && (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.stepIndex) + 1,
        totalSteps: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.totalSteps
    });
    function closeWithTab(event) {
        if (event.key === "Tab") {
            closeTooltip && closeTooltip();
            if (stepsLength &&
                isStepsMoreThanEdgeCase(stepsLength) &&
                closeEdgeCaseTriggered) {
                closeEdgeCaseTriggered();
            }
        }
    }
    function handleTooltipClose() {
        if (closeTooltip) {
            var stepElement = document.querySelector('.step[tabIndex="0"]');
            if (stepElement) {
                stepElement.tabIndex = -1;
            }
            closeTooltip();
            if (stepsLength &&
                isStepsMoreThanEdgeCase(stepsLength) &&
                closeEdgeCaseTriggered) {
                closeEdgeCaseTriggered();
            }
        }
    }
    var sentBackTo = useIntl().formatMessage({ id: "app.home.reviewsInProgress.sentBackTo" }, {
        name: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.sentBackTo
    });
    var skippedTo = useIntl().formatMessage({ id: "app.home.reviewsInProgress.skippedTo" }, {
        name: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.skippedTo
    });
    function isStatusSubmitted(assignees) {
        return assignees.some(function (assignee) { return assignee.status === submittedStatus; });
    }
    return (tooltipData && (_jsxs(_Fragment, { children: [_jsx("div", { className: "tooltip-pointer", style: { left: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.carrotPosition) + "px" }, children: _jsx(TooltipArrow, {}) }), _jsxs("div", { style: isSmallScreen
                    ? { marginLeft: "0" }
                    : { marginLeft: (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.positionX) + "px" }, className: isSmallScreen ? "tooltip-small-screen" : "task-step-tooltip", ref: tooltipRef, children: [!isSmallScreen && (_jsx(_Fragment, { children: _jsx("div", { className: "close-button", children: _jsx(WmButton, { "button-type": "navigational", "tooltip-position": "left", class: "-navigational hydrated", icon: "f156", tooltip: "Close", onClick: handleTooltipClose, onKeyDown: function (event) { return closeWithTab(event); } }) }) })), _jsxs("div", { className: "tooltip-header", children: [tooltipData.splitStep && isSmallScreen && (_jsx(JointAppointmentsIcon, {})), _jsx("div", { className: "tooltip-state ".concat(stepClass(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.state), " "), children: getTaskState(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.state) }), tooltipData.splitStep &&
                                tooltipData.state === inProgressStatus &&
                                isStatusSubmitted(tooltipData.assignees) && (_jsx("div", { className: "tooltip-state ".concat(stepClass(submittedStatus)), children: getTaskState(submittedStatus) })), _jsx("div", { className: "step-index", children: "(".concat(stepIndex, ")") })] }), !isSmallScreen && (_jsxs("div", { className: "flex-row -verticallycentered", children: [_jsx("div", { className: "step-name", children: tooltipData.name }), tooltipData.splitStep && (_jsx("div", { className: "split-step-icon", children: _jsx(JointAppointmentsIcon, {}) }))] })), tooltipData.state !== "SKIPPED" &&
                        tooltipData.state !== "ON_HOLD" && (_jsx(TooltipDate, { tooltipData: tooltipData })), tooltipData.skippedTo && (_jsxs("div", { className: "skipped-to flex-row -verticallycentered", children: [_jsx(SkippedToIcon, {}), " ", _jsx("span", { children: skippedTo })] })), tooltipData.sentBackTo && (_jsxs("div", { className: "sent-back-to flex-row -verticallycentered", children: [_jsx(SentBackIcon, {}), _jsx("span", { children: sentBackTo })] })), tooltipData.splitStep ? (_jsx(AssigneesJointAppointment, { tooltipData: tooltipData })) : (_jsx(Assignees, { tooltipData: tooltipData }))] })] })));
}
function completed(date) {
    return useIntl().formatMessage({ id: "app.home.reviewsInProgress.completed" }, {
        date: formatDateFullYear(date)
    });
}
function dueDate(date) {
    return useIntl().formatMessage({ id: "app.home.taskDueDate" }, {
        date: formatDateFullYear(date)
    });
}
function TooltipDate(_a) {
    var tooltipData = _a.tooltipData;
    var dueDays = useIntl().formatMessage({ id: "app.home.reviewsInProgress.dueDays" }, {
        daysNumber: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.daysUntilDue
    });
    return (tooltipData && (_jsx(_Fragment, { children: tooltipData.submittedDate ? (_jsx("div", { className: "date", children: completed(tooltipData.submittedDate) })) : (_jsx(_Fragment, { children: tooltipData.dueDateType === "FIXED" ? (_jsx("div", { className: "date", children: tooltipData.dueDate && dueDate(tooltipData.dueDate) })) : (_jsx("div", { className: "date", children: dueDays })) })) })));
}
function Assignees(_a) {
    var tooltipData = _a.tooltipData;
    var sysUser = useContext(AppContext).sysUser;
    function checkCurrentUserIsAssignee(assignee) {
        return ((sysUser === null || sysUser === void 0 ? void 0 : sysUser.firstName) === assignee.firstName &&
            sysUser.lastName === assignee.lastName);
    }
    return (_jsxs("div", { className: "assignee", children: [_jsxs("span", { children: [translate("app.home.reviewsInProgress.assignedTo"), " "] }), tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.assignees.map(function (assignee, index) {
                return (_jsxs("div", { children: [!checkCurrentUserIsAssignee(assignee) ? (_jsxs(_Fragment, { children: [_jsxs("span", { children: [" ", "".concat(assignee.firstName)] }), _jsx("span", { children: assignee.middleName ? assignee.middleName : "" }), _jsxs("span", { children: [" ", assignee.lastName] })] })) : (translate("app.home.reviewsInProgress.you")), index < tooltipData.assignees.length - 1 ? "," : ""] }, "".concat(tooltipData.id, " ").concat(index)));
            })] }));
}
function AssigneesJointAppointment(_a) {
    var tooltipData = _a.tooltipData;
    function checkSubmittedOrInProgress() {
        return ((tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.state) === "IN_PROGRESS" || (tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.state) === "SUBMITTED");
    }
    return (_jsxs("div", { className: "assignee-split-step", children: [_jsx("div", { className: "assignedTo", children: translate("app.home.reviewsInProgress.assignedToSplitStep") }), tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.assignees.map(function (assignee, index) {
                return (_jsxs("div", { className: checkSubmittedOrInProgress()
                        ? "assignees-list flex-row"
                        : "assignees-list", children: [checkSubmittedOrInProgress() && (_jsxs("div", { className: "assignee-icon", children: [assignee.status === "SUBMITTED" && _jsx(TickIcon, {}), assignee.status === "IN_PROGRESS" && _jsx(ClockIcon, {})] })), _jsxs("div", { children: [_jsx("div", { className: "assignee-name", children: _jsx("span", { children: "".concat(assignee.firstName, " ").concat(assignee.lastName) }) }), checkSubmittedOrInProgress() && (_jsx(_Fragment, { children: assignee.submittedDate ? (_jsx("div", { className: "assignee-date", children: completed(assignee.submittedDate) })) : (assignee.dueDate && (_jsx("div", { className: "assignee-date", children: dueDate(assignee.dueDate) }))) }))] })] }, "".concat(tooltipData.id, " ").concat(index)));
            })] }));
}
