import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ClockIcon, ExpandIcon, ExpandLess, OnHoldIcon, SentBackIcon, SkippedIcon, TickIcon } from "src/features/common/Icons";
import { stepClass } from "./WorkflowTaskStep";
import { WorkflowTaskStepTooltip } from "./WorkflowTaskStepTooltip";
export function WorkflowTaskStepSmallScreen(_a) {
    var step = _a.step, showStepIndex = _a.showStepIndex, tooltipData = _a.tooltipData, openTooltip = _a.openTooltip;
    function handleKeyPress(event, stepIndex) {
        if (event.key === "Enter") {
            openTooltip(stepIndex);
        }
        else if (event.key === "Tab" && step.stepIndex === showStepIndex) {
            openTooltip(stepIndex);
        }
    }
    return (_jsxs("div", { className: step.stepIndex === showStepIndex ? "box-shadow" : "", children: [_jsxs("div", { onClick: function () { return openTooltip(step.stepIndex); }, className: step.stepIndex === showStepIndex
                    ? "step-small-screen tabbing ".concat(stepClass(step.state), " expand-details")
                    : "step-small-screen hover tabbing ".concat(stepClass(step.state)), onKeyDown: function (event) { return handleKeyPress(event, step.stepIndex); }, tabIndex: 0, children: [_jsx("div", { className: "step-content", children: step.state === "NOT_STARTED" ? (step.name) : (_jsxs("div", { className: "flex-row in-progress-content", children: [_jsxs("div", { className: "clock-icon", children: [step.state === "IN_PROGRESS" && _jsx(ClockIcon, {}), step.state === "SUBMITTED" && _jsx(TickIcon, {}), step.state === "SENT_BACK" && (_jsx("div", { className: "sent-back", children: _jsx(SentBackIcon, {}) })), step.state === "SKIPPED" && (_jsx("div", { className: "skipped", children: _jsx(SkippedIcon, {}) })), step.state === "ON_HOLD" && (_jsx("div", { className: "on-hold", children: _jsx(OnHoldIcon, {}) }))] }), _jsx("div", { className: "step-name", children: step.name })] })) }), step.stepIndex !== showStepIndex ? (_jsx("div", { children: _jsx(ExpandIcon, {}) })) : (_jsx("div", { children: _jsx(ExpandLess, {}) }))] }), step.stepIndex === showStepIndex && tooltipData && (_jsx(WorkflowTaskStepTooltip, { tooltipData: tooltipData }))] }));
}
