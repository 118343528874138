var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useReducer, useRef, Fragment } from "react";
import { useIntl } from "react-intl";
import { WorkflowTaskStep } from "./WorkflowTaskStep";
import useSmallScreenView from "src/features/common/useSmallScreenView";
import { WorkflowTaskStepSmallScreen } from "./WorkflowTaskStepSmallScreen";
import { WorkflowTaskStepTooltip } from "./WorkflowTaskStepTooltip";
import { AppContext } from "src/contexts/AppContext";
import { WmButton } from "@watermarkinsights/ripple-react";
import { stepsForEdgeCase } from "src/features/common/constants";
import EdgeCaseArrowButton from "./EdgeCaseArrowButton";
import { openFlyout } from "src/static/js/commons.js";
import { WorkFlowTaskFlyout } from "./WorkflowTaskFlyout";
import translate from "src/i18n/translate";
var defaultAction = {
    type: "",
    showSteps: false,
    showTooltip: false,
    tooltipData: null,
    showTooltipStepIndex: null,
    showTooltipSmallScreen: false,
    tooltipDataSmallScreen: null,
    currentStepIndex: -1,
    clickedStep: -1,
    edgeCaseTriggered: false,
    isDrilldownOpen: false
};
var stateReducer = function (state, action) {
    var _a, _b;
    switch (action.type) {
        case "toggleSteps":
            return __assign(__assign({}, state), { showSteps: !state.showSteps });
        case "openTooltip":
            return __assign(__assign({}, state), { showTooltip: true });
        case "closeTooltip":
            return __assign(__assign({}, state), { showTooltip: false, clickedStep: -1 });
        case "setTooltipData": {
            return __assign(__assign({}, state), { tooltipData: action.tooltipData });
        }
        case "setTooltipIndexSmallScreen": {
            return __assign(__assign({}, state), { showTooltipStepIndex: action.showTooltipStepIndex });
        }
        case "toggleSmallScreenTooltip": {
            return __assign(__assign({}, state), { showTooltipSmallScreen: !state.showTooltipSmallScreen });
        }
        case "setTooltipDataSmallScreen": {
            return __assign(__assign({}, state), { tooltipDataSmallScreen: action.tooltipDataSmallScreen });
        }
        case "setCurrentStepIndex":
            return __assign(__assign({}, state), { currentStepIndex: (_a = action.currentStepIndex) !== null && _a !== void 0 ? _a : -1 });
        case "setClickedstepIndex":
            return __assign(__assign({}, state), { clickedStep: (_b = action.clickedStep) !== null && _b !== void 0 ? _b : -1 });
        case "setEdgeCaseStepsClicked":
            return __assign(__assign({}, state), { edgeCaseTriggered: true });
        case "resetEdgeCaseStepsClicked":
            return __assign(__assign({}, state), { edgeCaseTriggered: false });
        case "ToggleDrilldown":
            return __assign(__assign({}, state), { isDrilldownOpen: !state.isDrilldownOpen });
        default:
            return state;
    }
};
export function isStepsLessThanEqualToEdgeCase(stepsLength) {
    return stepsLength <= stepsForEdgeCase;
}
export function isStepsMoreThanEdgeCase(stepsLength) {
    return stepsLength > stepsForEdgeCase;
}
export function WorkFlowTaskProgress(_a) {
    var workflowTask = _a.workflowTask, totalWorkflows = _a.totalWorkflows;
    var initialState = {
        showSteps: totalWorkflows === 1,
        showTooltip: false,
        tooltipData: null,
        showTooltipStepIndex: null,
        tooltipDataSmallScreen: null,
        showTooltipSmallScreen: false,
        currentStepIndex: -1,
        clickedStep: -1,
        edgeCaseTriggered: false,
        isDrilldownOpen: false
    };
    var _b = useReducer(stateReducer, initialState), state = _b[0], dispatch = _b[1];
    var sysUser = useContext(AppContext).sysUser;
    var isAdminUser = sysUser === null || sysUser === void 0 ? void 0 : sysUser.roles.includes("administrator");
    var isSmallScreen = useSmallScreenView(1124);
    var tooltipRef = useRef(null);
    function toggleSteps() {
        dispatch(__assign(__assign({}, defaultAction), { type: "toggleSteps" }));
    }
    var toggleStepsLabel = useIntl().formatMessage({
        id: "app.home.reviewsInProgress.toggleSteps"
    });
    var workflowTaskLabel = useIntl().formatMessage({ id: "app.home.reviewsInProgress.workflowTaskLabel" }, { title: workflowTask.title });
    function showSmallScreenSteps(showSteps) {
        return isSmallScreen && showSteps;
    }
    function removeTabIndex(stepContainer, currentStepIndex) {
        if (stepContainer && currentStepIndex !== -1) {
            var previousElement = stepContainer.children[currentStepIndex];
            previousElement.tabIndex = -1;
        }
    }
    function focusRight(totalSteps, stepContainer, currentStepIndex, event) {
        if (totalSteps > 0) {
            dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
            if (isEdgeCaseTriggeredForFsRptSteps()) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setEdgeCaseStepsClicked" }));
            }
            var nextIndex_1 = (currentStepIndex + 1) % totalSteps;
            var nextElement_1 = stepContainer.children[nextIndex_1];
            nextElement_1.tabIndex = 0;
            nextElement_1.focus();
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: nextIndex_1 }));
            setTimeout(function () {
                openTooltip(event, nextIndex_1, nextElement_1);
            }, 100);
        }
    }
    function focusLeft(totalSteps, stepContainer, currentStepIndex, event) {
        if (totalSteps > 0) {
            dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
            if (isEdgeCaseTriggeredForFsRptSteps()) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setEdgeCaseStepsClicked" }));
            }
            var prevIndex_1 = currentStepIndex === -1
                ? totalSteps - 1
                : (currentStepIndex - 1 + totalSteps) % totalSteps;
            var prevElement_1 = stepContainer.children[prevIndex_1];
            prevElement_1.tabIndex = 0;
            prevElement_1.focus();
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: prevIndex_1 }));
            setTimeout(function () {
                openTooltip(event, prevIndex_1, prevElement_1);
            }, 100);
        }
    }
    var handleKeyDown = function (event) {
        var steps = workflowTask.steps;
        var totalSteps = steps.length;
        var currentStepIndex = state.clickedStep;
        var parent = event.target.closest(".task-progress-container");
        var stepContainer = parent.querySelector(".steps-container");
        removeTabIndex(stepContainer, currentStepIndex);
        switch (event.key) {
            case "ArrowRight":
                event.preventDefault();
                focusRight(totalSteps, stepContainer, currentStepIndex, event);
                break;
            case "ArrowLeft":
                event.preventDefault();
                focusLeft(totalSteps, stepContainer, currentStepIndex, event);
                break;
            case "Enter":
                event.preventDefault();
                if (isEdgeCaseTriggeredForFsRptSteps()) {
                    openEdgeCaseMode();
                }
                break;
            default:
                break;
        }
        if (event.key === "Tab" &&
            event.shiftKey &&
            isStepsLessThanEqualToEdgeCase(workflowTask.steps.length)) {
            dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
        }
    };
    function expandStepOnClick(stepIndex) {
        setTimeout(function () {
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: stepIndex }));
        }, 100);
    }
    function openTooltip(event, stepIndex, focusedElement, edgeCaseTriggered, direction) {
        setTimeout(function () {
            var selectedStep = workflowTask.steps.find(function (step) { return step.stepIndex === stepIndex; });
            var parent = event.target.closest(".task-progress-container");
            var stepContainer = parent.querySelector(".steps-container");
            var element = null;
            if (direction || (event.key && focusedElement)) {
                element = focusedElement;
            }
            else {
                edgeCaseTriggered
                    ? (element = stepContainer.children[stepIndex])
                    : (element = event.target.closest(".step"));
            }
            if (edgeCaseTriggered && event.key === "Enter") {
                element.tabIndex = 0;
                element.focus();
            }
            var rect = element.getBoundingClientRect();
            var parentElement = element.parentNode;
            var parentElementRect = parentElement.getBoundingClientRect();
            var left = isAdminUser
                ? rect.left - 355 + rect.width / 2
                : rect.left - 310 + rect.width / 2;
            var carrotPositionX = isAdminUser
                ? rect.left - 181 + rect.width / 2
                : rect.left - 130 + rect.width / 2;
            if (left < 0) {
                left = 0;
            }
            if (left > parentElementRect.width - 363) {
                left = parentElementRect.width - 363;
            }
            if (selectedStep && element) {
                var submittedDate = selectedStep.submittedDate, assignees = selectedStep.assignees, dueDate = selectedStep.dueDate, state_1 = selectedStep.state, stepIndex_1 = selectedStep.stepIndex, name = selectedStep.name, daysUntilDue = selectedStep.daysUntilDue, dueDateType = selectedStep.dueDateType, sentBackTo = selectedStep.sentBackTo, skippedTo = selectedStep.skippedTo, splitStep = selectedStep.splitStep, id = selectedStep.id;
                var tooltipData = {
                    totalSteps: workflowTask.steps.length,
                    name: name,
                    stepIndex: stepIndex_1,
                    submittedDate: submittedDate,
                    assignees: assignees,
                    dueDate: dueDate,
                    state: state_1,
                    positionX: left < 0 ? 0 : left,
                    daysUntilDue: daysUntilDue,
                    carrotPosition: carrotPositionX,
                    dueDateType: dueDateType,
                    sentBackTo: sentBackTo,
                    skippedTo: skippedTo,
                    splitStep: splitStep,
                    id: id
                };
                dispatch(__assign(__assign({}, defaultAction), { type: "openTooltip" }));
                dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipData", tooltipData: tooltipData }));
            }
        }, 200);
    }
    function openTooltipSmallScreen(index) {
        if (state.showTooltipStepIndex === index) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipIndexSmallScreen", showTooltipStepIndex: null }));
        }
        else {
            var selectedStep = workflowTask.steps.find(function (step) { return step.stepIndex === index; });
            if (selectedStep) {
                var submittedDate = selectedStep.submittedDate, assignees = selectedStep.assignees, dueDate = selectedStep.dueDate, state_2 = selectedStep.state, stepIndex_2 = selectedStep.stepIndex, name = selectedStep.name, daysUntilDue = selectedStep.daysUntilDue, dueDateType = selectedStep.dueDateType, sentBackTo = selectedStep.sentBackTo, skippedTo = selectedStep.skippedTo, splitStep = selectedStep.splitStep, id = selectedStep.id;
                var tooltipData = {
                    totalSteps: workflowTask.totalSteps,
                    name: name,
                    stepIndex: stepIndex_2,
                    submittedDate: submittedDate,
                    assignees: assignees,
                    dueDate: dueDate,
                    state: state_2,
                    daysUntilDue: daysUntilDue,
                    dueDateType: dueDateType,
                    sentBackTo: sentBackTo,
                    skippedTo: skippedTo,
                    splitStep: splitStep,
                    id: id
                };
                setTimeout(function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipIndexSmallScreen", showTooltipStepIndex: stepIndex_2 }));
                }, 300);
                dispatch(__assign(__assign({}, defaultAction), { type: "setTooltipDataSmallScreen", tooltipDataSmallScreen: tooltipData }));
            }
        }
    }
    function checkIfClickedElementIsStep(element) {
        var _a, _b;
        var stepContainer = (_b = (_a = tooltipRef.current) === null || _a === void 0 ? void 0 : _a.closest(".task-progress-container")) === null || _b === void 0 ? void 0 : _b.querySelector(".steps-container");
        var stepsArray = Array.from((stepContainer === null || stepContainer === void 0 ? void 0 : stepContainer.children) || []);
        return stepsArray.some(function (step) { return step.contains(element); });
    }
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (tooltipRef.current &&
                !tooltipRef.current.contains(event.target) &&
                !checkIfClickedElementIsStep(event.target) &&
                !event.target.closest(".right-button") &&
                !event.target.closest(".left-button")) {
                setTimeout(function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
                    isStepsMoreThanEdgeCase(workflowTask.steps.length) &&
                        dispatch(__assign(__assign({}, defaultAction), { type: "resetEdgeCaseStepsClicked" }));
                }, 300);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    function handleStepsClickedEdgeCase(event) {
        if (isStepsMoreThanEdgeCase(workflowTask.steps.length)) {
            event === null || event === void 0 ? void 0 : event.preventDefault();
            openEdgeCaseMode();
        }
        else {
            event === null || event === void 0 ? void 0 : event.preventDefault();
        }
    }
    function openEdgeCaseMode() {
        var inProgressStep = workflowTask.steps.find(function (step) { return step.state === "IN_PROGRESS"; });
        dispatch(__assign(__assign({}, defaultAction), { type: "setEdgeCaseStepsClicked" }));
        if (inProgressStep) {
            dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: inProgressStep === null || inProgressStep === void 0 ? void 0 : inProgressStep.stepIndex }));
            openTooltip(event, inProgressStep === null || inProgressStep === void 0 ? void 0 : inProgressStep.stepIndex, undefined, true);
        }
    }
    function isEdgeCaseTriggeredForFsRptSteps() {
        return (workflowTask.steps.length > stepsForEdgeCase && !state.edgeCaseTriggered);
    }
    var setIsDrilldownOpen = function () {
        dispatch(__assign(__assign({}, defaultAction), { type: "ToggleDrilldown" }));
    };
    var openDrilldownView = function () {
        setTimeout(function () { return openFlyout("drill-down-view", "course-charts", "fs-rpt-close-btn"); }, 400);
        setIsDrilldownOpen();
    };
    return (_jsxs("div", { className: totalWorkflows > 1 && !state.showSteps
            ? "task-progress-container tabbing  reduced-padding "
            : "task-progress-container tabbing workflowtasks-steps-table", tabIndex: 0, "aria-label": workflowTaskLabel, onKeyDown: function (event) { return handleKeyDown(event); }, onFocus: function () {
            if (!state.showTooltip &&
                isStepsLessThanEqualToEdgeCase(workflowTask.steps.length)) {
                dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: -1 }));
            }
        }, children: [_jsxs("div", { className: "task-name", children: [totalWorkflows > 1 && (_jsx("button", { className: state.showSteps
                            ? "expand-button mdi  mdi-menu-down"
                            : "expand-button mdi  mdi-menu-right", onClick: toggleSteps, "aria-label": toggleStepsLabel, onBlur: function () { return dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" })); } })), workflowTask.title] }), state.edgeCaseTriggered && (_jsx(EdgeCaseArrowButton, { closeTooltip: function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
                }, workflowTask: workflowTask, direction: "left", clickedStep: state.clickedStep, openTooltip: openTooltip, setClickedStep: function (stepIndex) {
                    return dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: stepIndex }));
                } })), !isSmallScreen && (_jsx("div", { className: state.edgeCaseTriggered
                    ? "steps-container arrow-margin"
                    : "steps-container", onClick: function (event) { return handleStepsClickedEdgeCase(event); }, children: workflowTask.steps.map(function (step) {
                    return (_jsx(WorkflowTaskStep, { step: step, allExpanded: workflowTask.steps.length <= 4, openTooltip: openTooltip, clickedStep: state.clickedStep, expandStepOnClick: expandStepOnClick, stepsLength: workflowTask.steps.length }, step.id));
                }) })), state.edgeCaseTriggered && (_jsx(EdgeCaseArrowButton, { closeTooltip: function () {
                    dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
                }, workflowTask: workflowTask, direction: "right", clickedStep: state.clickedStep, openTooltip: openTooltip, setClickedStep: function (stepIndex) {
                    return dispatch(__assign(__assign({}, defaultAction), { type: "setClickedstepIndex", clickedStep: stepIndex }));
                } })), state.showTooltip && state.tooltipData && (_jsx("div", { className: "step-tooltip", children: _jsx(WorkflowTaskStepTooltip, { tooltipData: state.tooltipData, tooltipRef: tooltipRef, closeTooltip: function () {
                        dispatch(__assign(__assign({}, defaultAction), { type: "closeTooltip" }));
                    }, closeEdgeCaseTriggered: function () {
                        dispatch(__assign(__assign({}, defaultAction), { type: "resetEdgeCaseStepsClicked" }));
                    }, stepsLength: workflowTask.steps.length }) })), showSmallScreenSteps(state.showSteps) && (_jsx("div", { className: "steps-container", children: workflowTask.steps.map(function (step) {
                    var _a;
                    return (_jsx(WorkflowTaskStepSmallScreen, { step: step, showStepIndex: (_a = state.showTooltipStepIndex) !== null && _a !== void 0 ? _a : -1, tooltipData: state.tooltipDataSmallScreen, showTooltipSmallScreen: state.showTooltipSmallScreen, openTooltip: openTooltipSmallScreen }, "ss-".concat(step.id)));
                }) })), _jsx("div", { className: "view-more", children: _jsx(WmButton, { buttonType: "textonly", onClick: function () { return openDrilldownView(); }, children: _jsx("div", { className: "view-all-steps-link", children: translate("app.home.WorkflowTaskProgress.steps") }) }) }), state.isDrilldownOpen && (_jsxs(Fragment, { children: [_jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 }), _jsx(WorkFlowTaskFlyout, { setIsDrilldownOpen: setIsDrilldownOpen, workflowTask: workflowTask }), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-top", tabIndex: 0 })] })), _jsx("div", { className: "sr-only", id: "course-dialog-wrapper-flyout-bottom", tabIndex: 0 })] }));
}
